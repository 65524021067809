<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main change-schedule">
        <div class="back-button-wrapper" @click="onClickClose">
            <img
                class="m-r-2 back-button"
                :src="require(`@/assets/images/icons/close.png`)"
                width="24px"
                height="24px"
            />
            <span class="f-18 m-l-12 c-black">약속 변경</span>
        </div>
        <section class="section m-t-24">
            <div class="title">
                <div class="text" v-html="'내 약속 내용'" />
            </div>
            <div class="content">
                <div class="desc li-dot m-b-16">일시 : {{ this.scheduleTime }}</div>
                <div class="desc li-dot m-b-16">
                    지역 : {{ options.schedule.schedule_place }}에서 {{ options.schedule.schedule_type }}예정
                </div>
                <div class="desc li-dot m-b-16">
                    <div class="place-wrap">
                        <div class="m-r-4">장소 : {{ options.schedule.restaurant_name || '미정' }}</div>
                        <div v-if="options.schedule.restaurant_name !== null" class="place-map">
                            <img
                                :src="require('@/assets/images/icons/location_black.png')"
                                alt=""
                                widtt="16"
                                height="16"
                            />
                            <span @click="showMap">지도보기</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section m-t-24">
            <div class="title">
                <div class="text" v-html="'안내 사항'" />
            </div>
            <div class="content">
                <div class="desc li-dot m-b-16">약속 변경을 위해 임시로 채팅방을 개설해드립니다.</div>
                <div class="desc li-dot m-b-16">
                    아래 변경 요청 내용과 사유를 작성하시면, 채팅방 개설과 함께 상대방에게 정보가 전달됩니다.
                </div>
            </div>
        </section>
        <section class="section m-t-24">
            <div class="title">
                <div class="text" v-html="'어떤걸 바꾸고 싶으세요?'" />
            </div>
            <div class="content">
                <div class="flex-row">
                    <div
                        class="desc m-b-16 btn type-btn m-r-8"
                        :class="{ selected: selectedType === '날짜/시간' }"
                        @click="selectedType = '날짜/시간'"
                    >
                        날짜/시간을<br />바꾸고 싶어요
                    </div>
                    <div
                        class="desc m-b-16 btn type-btn"
                        :class="{ selected: selectedType === '지역/장소' }"
                        @click="selectedType = '지역/장소'"
                    >
                        지역/장소만<br />바꾸고 싶어요
                    </div>
                </div>
            </div>
        </section>
        <section v-show="selectedType === '날짜/시간'" class="section m-t-24 day-time-input">
            <div class="title">
                <div class="text" v-html="'변경 요청 내용'" />
            </div>
            <TextareaWithX
                :placeholder="'날짜와 시간을 포함하여 원하시는 일정을 최소 3개 이상 작성해주세요.'"
                v-model="dayTimeInput"
                :ref="'comment'"
            />
            <div class="content">
                <div class="desc li-dot m-t-16">
                    변경하고 싶은 날짜와 시간, 만남 방식을 최대한 많이(최소 3개) 적어주세요.
                </div>
                <div class="desc m-t-16 example m-l-16 p-r-16">
                    예시)<br />
                    아래와 같이 변경 희망합니다. <br />- 7월 1일 월요일 오후 7시 , 식사 <br />- 7월 6일 토요일 오후
                    12~2시, 식사/커피 모두 가능 <br />- 7월 13일 토요일 오후 12시~오후 7시, 식사/커피 모두 가능
                </div>
            </div>
        </section>
        <section v-show="selectedType === '지역/장소'" class="section m-t-24 place-input">
            <div class="title">
                <div class="text" v-html="'변경 요청 내용'" />
            </div>
            <TextareaWithX
                :placeholder="'변경 희망 지역 혹은 장소를 적어주세요.'"
                v-model="placeInput"
                :ref="'comment'"
            />
            <div class="content">
                <div class="desc li-dot m-t-16">
                    우선 지역을 변경하고 나중에 구체적인 장소(식당/카페)를 정하려고 하는 경우 ‘지역’ 만 적어주세요.
                </div>
                <div class="desc m-t-16 example m-l-16 p-r-16">
                    예시) 가로수길 , 청담, 한남, 강남역 쪽으로 변경 희망합니다.
                </div>
                <div class="desc li-dot m-t-16">
                    장소 변경만 원하시는 경우 해당 장소의 이름과 네이버 지도 주소를 함께 전달해주세요.
                </div>
                <div class="desc m-t-16 example m-l-16 p-r-16">
                    예시) 페어링룸(https://naver.me/xYQTT11Z )으로 변경 희망합니다.
                </div>
                <div class="desc li-dot m-t-16">
                    지역과 장소 둘 다 변경하시려는 경우 지역과 장소 이름, 네이버 지도 주소를 함께 전달해주세요.
                </div>
            </div>
        </section>
        <section v-if="selectedType !== null" class="section m-t-12 reason-input">
            <div class="title">
                <div class="text" v-html="'변경 요청 사유'" />
            </div>
            <TextareaWithX
                :placeholder="'최대한 구체적으로 이유를 적어주세요.'"
                v-model="reasonInput"
                :ref="'comment'"
            />
            <div class="content">
                <div class="desc li-dot m-t-16">해당 메세지는 채팅방 생성 시 자동으로 전달됩니다.</div>
                <!-- <div class="desc m-t-16 example m-l-16 p-r-16">
                    (별 이유 없거나 납득이 어려운 이유로 변경을 요청하면 상대방이 기분이 상하거나, 만날 의지가 별로
                    없다고 오해할 수 있어요.)
                </div> -->
            </div>
        </section>
        <BottomButton @click="submit" :disabled="disabled" :label="'변경 요청하기'" />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalChangeSchedule',
    props: ['options'],
    methods: {
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.options.schedule.restaurant_url,
            })
        },
        async onClickClose() {
            const idx = await this.$modal.basic({
                body: '약속 변경 요청이 되지 않았습니다. <br />원래대로 약속을 진행하시겠습니까?',
                buttons: [
                    { label: '변경 요청하기', class: 'btn-default' },
                    { label: '원래대로 약속 진행', class: 'btn-primary' },
                ],
            })
            if (idx === 1) {
                this.$emit('close')

                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#FAF0E1',
                        bottom: '#FFF9EF',
                    },
                })
            }
        },
        async submit() {
            try {
                const idx = await this.$modal.basic({
                    body:
                        '작성하신 내용들을 바탕으로 두 분의 약속 일정 조율을 위해 채팅방이 개설될 예정입니다. <br /> 채팅방을 개설하시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: '채팅방 개설하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    let reasonValue = null

                    if (this.dayTimeInput !== null) {
                        reasonValue = this.dayTimeInput
                    } else if (this.placeInput !== null) {
                        reasonValue = this.placeInput
                    }

                    const payload = {
                        id: this.options.schedule.schedule_id,
                        user_id: this.me.user_id,
                        change_type: 'change',
                        reason: reasonValue,
                        content: this.reasonInput,
                        change_detail: this.selectedType,
                    }
                    await scheduleService.changeScheduleRequest(payload)
                    try {
                        const index = await this.$modal.basic({
                            body:
                                '채팅방이 개설되었습니다. <br /><br />' +
                                '약속 조율 후, 채팅방 하단의 버튼 중 1개를 반드시 클릭하여 완료해주세요.' +
                                '<br /><br />' +
                                '<img style="margin: 0 0 0 -4px;" src="' +
                                require('@/assets/images/chat/schedule_btn.png') +
                                '" alt="schedule_btn">',
                            buttons: [
                                {
                                    label: '확인',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (index === 0) {
                            this.$emit('close')
                            this.$store.dispatch('loadChats', true)
                            this.$router.push({ name: 'ChatsPage' })
                            this.$toast.success('채팅방이 열렸습니다.')
                        }
                    } catch (err) {
                        console.error(err)
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
                this.$router.go(-1)
                this.$router.push({ name: 'ChatsPage' })
            }
        },
    },
    data: () => ({
        selectedType: null,
        dayTimeInput: null,
        placeInput: null,
        reasonInput: null,
    }),
    computed: {
        disabled() {
            if (!this.selectedType) {
                return true
            } else {
                if (!this.reasonInput) return true
                if (this.selectedType === '날짜/시간') {
                    return this.dayTimeInput === null || this.dayTimeInput === ''
                } else {
                    return this.placeInput === null || this.placeInput === ''
                }
            }
        },
        scheduleTime() {
            const date = new Date(this.options.schedule.date_confirmed)
            const dayArr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            const scheduleTime = `${date.getMonth() + 1}월 ${date.getDate()}일 ${this.$translate(
                dayArr[this.$moment(date).day() % 7]
            )}요일 ${date.getHours()}시 ${date.getMinutes().toString().padEnd(2, '0')}분`

            return scheduleTime
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>
<style scoped lang="scss">
.change-schedule {
    overflow-y: auto;

    .section {
        margin-bottom: 48px;
        padding: 0 16px;
        color: black;

        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .content {
            font-size: 16px;
            .type-btn {
                font-size: 14px;
                color: $grey-06;
                padding-left: 16px;
                border: 1px solid $grey-02;

                &.selected {
                    border: 1px solid $blue-primary !important;
                    background-color: #f0f8ff;
                    color: $blue-primary;
                }
            }
            .place-map {
                margin-left: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 69px;
                width: fit-content;
                height: 24px;
                background: $grey-03;
                border-radius: 100px;
                color: #111;
            }
            .place-wrap {
                display: flex;
                align-items: center;
            }
            .example {
                font-size: 14px;
                color: #787878;
            }
            .desc {
                &.li-dot {
                    display: list-item;
                    align-items: flex-start;
                    margin-left: 16px;
                }
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
